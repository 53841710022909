.viewPost-container {
    margin-top: 15rem;
    margin-bottom: 10rem;

    @media (max-width: 800px) {
        margin-top: 10rem;
        margin-bottom: 1rem;
    }
}

.postContainer {
    padding-top: 4rem;

    //   display: flex;
}

.contain {
    position: relative;
    margin: 0 auto;
    max-width: 120rem;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: start;
    gap: 3rem;

    @media (max-width: 800px) {
        display: block;
    }
}

.post-header {
    margin: 0 auto;
    max-width: 120rem;

    &-button {
        font-size: 1.5rem;
        width: 10rem;
        background-color: $primary-color;
        border-radius: 5rem;
        color: white;
        margin-left: 1rem;

        @media (max-width: 800px) {
            margin-left: 3rem;
            margin-bottom: 3rem;
        }

        &-text {
            margin-left: 0.6rem;
        }
    }
}

.post-img {
    display: flex;
    float: left;
    width: 100%;
    height: 100%;
    padding: 3rem;

    @media (max-width: 800px) {
        padding: 0 3rem;
        margin-bottom: 2rem;
    }
}

.post-body {
    @media (max-width: 800px) {
        //margin: 0 auto;
        width: 95%;
        padding: 0 3rem;
    }

    &-title {
        font-size: 2rem;
        font-weight: 700;
        color: $secondary-color;
        margin: 3rem 0;

        @media (max-width: 800px) {
            margin-bottom: 2rem;
        }
    }

    &-content {
        font-size: 1.6rem;
    }
}

@media (max-width: 1154px) {
    .contain > h1 {
        text-align: center;
        margin-bottom: 2%;
        text-decoration: underline $primary-color;
        font-size: 2.5rem;
        margin-left: 3rem;
        padding: 2rem;
    }

    .postContainer {
        padding-top: 4rem;
        // display: flex;
    }

    .post-Content {
        display: grid;
        justify-content: center;
    }

    .postIMG {
        display: flex;
        float: left;
        width: 50rem;
        height: 35rem;
        padding: 2rem 3rem 0rem 3rem;
        margin: auto;

        @media (max-width: 800px) {
            width: 100%;
            height: 100%;
            padding: 0;
        }
    }
    .postBody {
        padding: 2rem 3rem 0rem 3rem;
        font-size: 1.5rem;
        max-width: 90rem;
    }
}
