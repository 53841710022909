.single-new-arrival-bg {
    // width: 200px;
    min-height: 280px;
    position: relative;

    img {
        width: 280px;
        height: 280px;
        object-fit: cover;
    }
}

.single-new-arrival {
    h4 {
        margin-top: 2rem;
        text-align: left;
        display: block;
    }
}

.section-header {
    h2 {
        text-align: left;

        @media (max-width: 800px) {
            font-size: 24px;
            margin-left: 60px;
        }

        @media (max-width: 800px) {
            margin-left: 25px;
        }
    }
}
