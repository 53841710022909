.consent-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}
.consentBanner {
    background-color: whitesmoke;
    // color: black;

    position: fixed;
    bottom: 50px;
    right: -370px;
    max-width: 400px;
    width: 100%;
    border-radius: 8px;
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px 25px 22px;
    opacity: 1;
}

.consentBanner {
    right: 27px;
}

.consentInfo {
    padding: 0 0 1rem 0;
}

.consentInfo p {
    color: #333;
    font-size: 16px;
}

.consentInfo a {
    color: $primary-color;
    text-decoration: underline;
}

.consentHeading > h1 {
    color: $primary-color;
    font-weight: 600;
    padding: 0 0 1.5rem 0;
}

.consentButtons {
    display: flex;
    margin-top: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.button .button {
    border: none;
    color: #fff;
    padding: 0px 0;
    border-radius: 50px;
    background-color: $primary-color;
    cursor: pointer;
    transition: all 0.2s ease;
}

#acceptBtn {
    background-color: $primary-color;
    color: #fff;
    transition: 0.3s ease-in;
    border-radius: 50px;
    height: 4rem;
}

#acceptBtn:hover {
    background-color: $primary-color;
    color: #fff;
    transition: 0.3s ease-in;
}

.hidebanner {
    display: none !important;
}

#readMore a {
    color: $primary-color;
}

#readMore {
    margin-right: 1rem;
    border: 1px solid $primary-color;
    background-color: #fff;
    color: $primary-color;
    height: 4rem;
    border-radius: 50px;
}

// Overlay

.overlay {
    opacity: 0.5;
}

@media (max-width: 900px) {
    .consentBanner {
        left: 50%;
        bottom: 100px;
        transform: translateX(-50%);
    }
}
@media (max-width: 470px) {
    .consentBanner {
        //bottom: 40px;
        max-width: 310px;
    }
}

@media (max-width: 400px) {
    .consentBanner {
        //right: 5rem;
        //bottom: 30px;
        max-width: 290px;
    }
}

@media (max-height: 580px) {
    .consentBanner {
        //right: 7px;
        //top: 9rem;
        bottom: 0.1rem;
    }
}
