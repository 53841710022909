.nopage {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    // box-shadow: 0 0.2rem 0.4rem rgba($color: #000000, $alpha: 0.18);
    // margin-bottom: 4rem;
}

.nopage>h1 {
    font-size: 5rem;
    padding: 3rem;
}

.logo404 {
    height: 20rem;
}

@media (max-width: 550px) {
    .nopage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .nopage>h1 {
        font-size: 4rem;
        padding: 2rem 1rem;
    }

    
}