.contact-modal {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background: transparent;
    transition: all ease 0.4s;
    z-index: 1000;

    &-inner {
        z-index: 1500;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);
        border-radius: 0.5rem;
        background-color: $color-white;
        width: 65%;
        max-width: 70rem;
        padding: 3rem 0 0 0;

        h3 {
            padding-left: 12%;
            color: $primary-color;
            font-size: 2.2rem;
        }

        small {
            display: block;
            margin-top: 1rem;
            padding-left: 12%;
            font-size: 1.4rem;
            // color: $primary-color;
        }
    }
}

.contact-form-modal {
    width: 10rem;
}

// media queries for contact modal
@media (max-width: 900px) {
    .contact-modal {
        &-inner {
            top: 45%;
            width: 90%;
            height: 65%;
            overflow-y: auto;
        }
    }
}
@media (max-width: 600px) {
    .contact-modal {
        &-inner {
            top: 50%;
            width: 90%;
            height: 70%;
            overflow-y: auto;
        }
    }
}

// @media (max-width: 400px) {
//     .contact-modal {
//         height: 60rem;
//         top: 0rem;
//     }
// }

// @media (max-width: 350px) {
//     .contact-modal {
//         top: 25rem;
//         height: 25rem;
//     }
// }
