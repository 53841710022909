.Text-container {
    height: 50vh;
    margin-top: 17rem;
}

.Text-container>h1 {
    padding: 0rem 3rem;
    font-size: 24px;
    color: $primary-color;
}

.Text-container>p {
    padding: 3rem 3rem;
}

@media (max-width: 600px) {
    .Text-container {
        height: 90vh;
    }
}