.tabs {
    padding: 1rem 10rem;
    box-shadow: inset 0.2rem 0.4rem 0.8rem rgba($color: #696969, $alpha: 0.2);

    &_wrapper {
        width: 100%;
        padding: 0 3rem;

        @media screen and (max-width: 500px) {
            padding: 0 1rem;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
        }

        .help-tab {
            @media screen and (max-width: 500px) {
                display: inline-block;
                flex: 0 0 13rem; // flex-grow: 0, flex-shrink: 0, flex-basis: 100px
                width: 14rem;
            }
        }
    }

    &_heading {
        margin-top: 5rem;
        color: $primary-color;
        padding-left: 3rem;
        margin-bottom: 3rem;
        font-size: 2.5rem;
        font-weight: 600;
    }

    &_content_wrapper {
        padding: 5rem;

        @media screen and (max-width: 500px) {
            padding: 1.5rem;
        }

        .image-container {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &_content {
        width: 85%;
        padding: 3rem 5rem 0 3rem;

        h1 {
            @media screen and (max-width: 500px) {
                margin-top: 1.6rem;
            }
        }

        &-2 {
            padding: 0 5rem 0 3rem;

            @media screen and (max-width: 500px) {
                padding: 0 5rem 0 0rem;
            }
        }
    }

    span {
        display: inline-block;
        padding: 1.5rem;
        font-size: 1.4rem;
        cursor: pointer;
        margin: 0.1rem;
        transition: all 0.2s ease;
        margin-top: 1rem;

        &:not(.active) {
            border-bottom: 0.1rem solid $grey-color;
        }

        &.active {
            border-top: 0.1rem solid $medium-grey-color;
            border-right: 0.1rem solid $medium-grey-color;
            border-left: 0.1rem solid $medium-grey-color;
            border-radius: 0.4rem 0.4rem 0 0;
            color: $secondary-color;
            font-weight: 700;
            background: $color-white;
        }
    }
}

.will-container {
    width: 100rem;
}

.will-heading {
    margin: 2rem 0 4rem 0;

    &-2 {
        margin: 4rem 0;
    }
}

.will-button {
    margin-top: 3.5rem;
    padding: 1.5rem;
    width: 15rem;
    border: none;
    outline: none;
    border-radius: 10rem;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
    background: $primary-color;
    color: white;
    font-size: 1.6rem;
}

@media (max-width: 1199px) {
    .tabs {
        margin-top: 10rem;
        padding-bottom: 0rem;
    }
    .will-container {
        width: 80rem;
    }
}

@media (max-width: 1143px) {
    .tabs_content_wrapper > .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .col-sm-6 {
        width: 60rem;
    }

    .tabs_content {
        width: 50rem;
    }

    .tabs_content > h3 {
        font-size: 3rem;
        padding: 1rem;
    }
    .will-container {
        width: 60rem;
    }
}

@media (max-width: 850px) {
    .tabs {
        margin-top: 10rem;
        padding: 1rem 3rem;
    }
    .will-container {
        width: 50rem;
    }
}

@media (max-width: 650px) {
    .tabs {
        padding: 1rem 3rem;
    }
    .tabs_content_wrapper > .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .col-sm-6 {
        width: 35rem;
    }

    .tabs_content {
        width: 100%;
        padding: 0rem;
    }

    .tabs_content > h3 {
        font-size: 2.5rem;
        padding: 1rem;
    }

    .will-button {
        padding: 1.5rem;
        width: 13rem;
    }
    .will-container {
        width: 35rem;
    }
}

@media (max-width: 420px) {
    .tabs {
        margin-top: 10rem;
        padding: 1rem 3rem;
    }
    .will-container {
        width: 30rem;
    }
}
