// abstracts
@import './abstracts/variable';
@import './layouts/layouts.scss';

// components
@import './components/footer';
@import './components/navbar';
@import './components/carousel';
@import './components/tabs';
@import './components/volunteer';
@import './components/contact-form';
@import './components/map';
@import './components/contact_modal';
@import './components/payment_form';
@import './components/donateForm';
@import './components/consentBanner';

// pages
@import './pages/home.scss';
@import './pages/contactus.scss';
@import './pages/news.scss';
@import './pages/viewPost.scss';
@import './pages/aboutsus.scss';
@import './pages/noPage.scss';
@import './pages/footer_pages.scss';

// utilities
@import './utilities_styles/notify.scss';
body {
    position: relative;
    font-size: 62.5%; // 1rem = 10px
    padding: 0 !important;
    transition: all 0.3s ease;
}

// 📝 see : https://fkhadra.github.io/react-toastify/how-to-style/
.main-toast-styles {
    font-size: 1.5rem;
    font-weight: 400;
}

.Toastify__toast-body > div:nth-child(2) {
    width: 22rem;
}

// mobile alerts must be full screen
@media screen and (max-width: 50rem) {
    .Toastify__toast-body > div:nth-child(2) {
        width: 100vw;
    }
}

