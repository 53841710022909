//@use '../base';


.row {
  position: relative;
  // display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next row if there's not enough space */
  margin-right: -1.5rem; /* Negative margin to compensate for gutter spacing */
  margin-left: -1.5rem; /* Negative margin to compensate for gutter spacing */
}

// desktop classes
/* Extra Small Devices (phones, less than 576px) */
@media (min-width: 575.98px) {
  .xs-1 {
    width: 8.33%; /* 1/12 */
  }
  .xs-2 {
    width: 16.66%; /* 2/12 */
  }
  .xs-3 {
    width: 25%; /* 3/12 */
  }
  .xs-4 {
    width: 33.33%; /* 4/12 */
  }
  .xs-5 {
    width: 41.66%; /* 5/12 */
  }
  .xs-6 {
    width: 50%; /* 6/12 */
  }
  .xs-7 {
    width: 58.33%; /* 7/12 */
  }
  .xs-8 {
    width: 66.66%; /* 8/12 */
  }
  .xs-9 {
    width: 75%; /* 9/12 */
  }
  .xs-10 {
    width: 83.33%; /* 10/12 */
  }
  .xs-11 {
    width: 91.66%; /* 11/12 */
  }
  .xs-12 {
    width: 100%; /* 12/12 */
  }
}
/* Add similar media queries for other breakpoints */
/* Small Devices (tablets, 576px and up) */
@media (min-width: 576px) {
  .sm-1 {
    width: 8.33%; /* 1/12 */
  }
  .sm-2 {
    width: 16.66%; /* 2/12 */
  }
  .sm-3 {
    width: 25%; /* 3/12 */
  }
  .sm-4 {
    width: 33.33%; /* 4/12 */
  }
  .sm-5 {
    width: 41.66%; /* 5/12 */
  }
  .sm-6 {
    width: 50%; /* 6/12 */
  }
  .sm-7 {
    width: 58.33%; /* 7/12 */
  }
  .sm-8 {
    width: 66.66%; /* 8/12 */
  }
  .sm-9 {
    width: 75%; /* 9/12 */
  }
  .sm-10 {
    width: 83.33%; /* 10/12 */
  }
  .sm-11 {
    width: 91.66%; /* 11/12 */
  }
  .sm-12 {
    width: 100%; /* 12/12 */
  }
}

/* Medium Devices (desktops, 768px and up) */
@media (min-width: 768px) {
  .md-1 {
    width: 8.33%; /* 1/12 */
  }
  .md-2 {
    width: 16.66%; /* 2/12 */
  }
  .md-3 {
    width: 25%; /* 3/12 */
  }
  .md-4 {
    width: 33.33%; /* 4/12 */
  }
  .md-5 {
    width: 41.66%; /* 5/12 */
  }
  .md-6 {
    width: 50%; /* 6/12 */
  }
  .md-7 {
    width: 58.33%; /* 7/12 */
  }
  .md-8 {
    width: 66.66%; /* 8/12 */
  }
  .md-9 {
    width: 75%; /* 9/12 */
  }
  .md-10 {
    width: 83.33%; /* 10/12 */
  }
  .md-11 {
    width: 91.66%; /* 11/12 */
  }
  .md-12 {
    width: 100%; /* 12/12 */
  }
}


/* Large Devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .lg-1 {
    width: 8.33%; /* 1/12 */
  }
  .lg-2 {
    width: 16.66%; /* 2/12 */
  }
  .lg-3 {
    width: 25%; /* 3/12 */
  }
  .lg-4 {
    width: 33.33%; /* 4/12 */
  }
  .lg-5 {
    width: 41.66%; /* 5/12 */
  }
  .lg-6 {
    width: 50%; /* 6/12 */
  }
  .lg-7 {
    width: 58.33%; /* 7/12 */
  }
  .lg-8 {
    width: 66.66%; /* 8/12 */
  }
  .lg-9 {
    width: 75%; /* 9/12 */
  }
  .lg-10 {
    width: 83.33%; /* 10/12 */
  }
  .lg-11 {
    width: 91.66%; /* 11/12 */
  }
  .lg-12 {
    width: 100%; /* 12/12 */
  }
}