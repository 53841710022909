.home_description_text {
    font-size: 1.6rem;
    padding: 7rem 8rem;

    .col-lg-5 {
        padding-bottom: 4rem;
    }

    h1 {
        color: $primary-color;
        font-weight: 600;
        font-size: 2.5rem;
        margin-bottom: 3rem;
    }

    .img-container {
        padding: 0.3rem;

        &-inner {
            height: 45rem;
            // background: $secondary-color;
            padding: 1.5rem;
            box-shadow: inset 0 0.4rem 0.8rem
                rgba($color: #000000, $alpha: 0.18);

            @media screen and (max-width: 600px) {
                height: 30rem;
            }
        }

        img {
            box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);

            object-position: top;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.home_description_text {
    height: 70rem;
    box-sizing: border-box;

    @media screen and (max-width: 80rem) {
        height: 110rem;
    }
}
//-- home_description_text media query ----------------------------------------------------//

// for home description image height
@media (max-width: 1199px) {
    .home_description_text {
        box-sizing: border-box;

        .img-container {
            padding: 0.3rem;

            &-inner {
                height: 70rem;
                // background: $secondary-color;
                padding: 1.5rem;
                box-shadow: inset 0 0.4rem 0.8rem
                    rgba($color: #000000, $alpha: 0.18);
            }

            img {
                box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);

                object-position: top;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (max-width: 1190px) {
    .home_description_text {
        .img-container {
            padding: 0.3rem;

            &-inner {
                height: 70rem;
                // background: $secondary-color;
                padding: 1.5rem;
                box-shadow: inset 0 0.4rem 0.8rem
                    rgba($color: #000000, $alpha: 0.18);
            }

            img {
                box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);

                object-position: top;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (max-width: 1395px) {
    .home_description_text {
        .img-container {
            padding: 0.3rem;

            &-inner {
                height: 60rem;
                // background: $secondary-color;
                padding: 1.5rem;
                box-shadow: inset 0 0.4rem 0.8rem
                    rgba($color: #000000, $alpha: 0.18);
            }

            img {
                box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);

                object-position: top;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

// average screen
@media (max-width: 590px) {
    .home_description_text {
        height: inherit;
        font-size: 1.6rem;
        padding: 3rem 1.5rem;

        .col-lg-5 {
            padding-bottom: 4rem;
        }

        h1 {
            color: $primary-color;
            font-weight: 600;
            font-size: 2.5rem;
            margin-bottom: 3rem;
        }

        .img-container {
            padding: 0.3rem;

            &-inner {
                height: 25rem;
                // background: $secondary-color;
                padding: 1.5rem;
                box-shadow: inset 0 0.4rem 0.8rem
                    rgba($color: #000000, $alpha: 0.18);
            }

            img {
                box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);

                object-position: top;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

// very small screen
@media (max-width: 350px) {
    .home_description_text {
        font-size: 1.6rem;
        padding: 3rem 1.3rem;

        .col-lg-5 {
            padding-bottom: 4rem;
        }

        h1 {
            color: $primary-color;
            font-weight: 600;
            font-size: 2.2rem;
            margin-bottom: 3rem;
        }

        .img-container {
            padding: 0.1rem;

            &-inner {
                // background: $secondary-color;
                height: 35rem;
                padding: 1.5rem;
                box-shadow: inset 0 0.4rem 0.8rem
                    rgba($color: #000000, $alpha: 0.18);
            }

            img {
                box-shadow: 0 0.4rem 0.8rem rgba($color: #000000, $alpha: 0.5);

                object-position: top;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
