.news {
    padding: 10rem 15rem 5rem 15rem;

    &_latest {
        padding: 2rem;
        border-bottom: solid 0.1rem rgb(230, 230, 230);

        h2 {
            color: $secondary-color;
            font-size: 2.6rem;
            margin: 3rem 0;
        }

        h3 {
            font-size: 2rem;
        }

        &-item {
            margin-bottom: 3rem;

            &-title {
                margin: 2rem 0 1.5rem 0;
                color: $primary-color;
                font-weight: 500;
                font-size: 2.5rem;
            }

            // &-body {
            // }

            span {
                font-size: 1.3rem;
                color: rgb(108, 107, 107);
                margin-top: 1rem;
            }
        }

        &-image-container {
            height: 40rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 0.5rem;
            }
        }
    }

    &_older {
        h3 {
            margin: 3rem 0;
            font-size: 2rem;
            margin-left: 2rem;
        }

        &-item {
            &:hover {
                transform: scale(1.01);
                cursor: pointer;
                box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.12);
            }

            transition: all 0.3s ease;
            padding: 2rem 2rem;
            border-bottom: solid 0.1rem rgb(230, 230, 230);

            &-container {
                height: 10rem;
                border-radius: 0.5rem;
            }

            display: grid;
            grid-template-columns: 15rem 60% 15%;
            gap: 2rem;
            align-items: center;

            &-title {
                color: $secondary-color;

                @media (max-width: 800px) {
                    margin-bottom: 0.6rem;
                }
            }
            &-body {
                font-size: 1.4rem;
                width: 80%;

                @media (max-width: 800px) {
                    display: none;
                }
            }

            span {
                font-size: 1.2rem;
                color: rgb(108, 107, 107);
                margin-top: 2rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 0.4rem;
            }

            button {
                box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.12);
                border: none;
                outline: none;
                padding: 1.4rem 1rem;
                background-color: $primary-color;
                border-radius: 10rem;
                font-size: 1.5rem;
                width: 16rem;
                color: $color-white;
                cursor: pointer;
                justify-self: flex-end;
            }
        }
    }
}

// news header media queries
@media (max-width: 803px) {
    .news {
        padding: 7rem 2rem 5rem 2rem;

        &_latest {
            padding: 2rem;
            border-bottom: solid 0.1rem rgb(230, 230, 230);

            h2 {
                color: $secondary-color;
                font-size: 2.6rem;
                margin: 3rem 0;
            }

            h3 {
                margin-top: 4rem;
                font-size: 2rem;
            }

            &-item {
                margin-bottom: 3rem;

                &-title {
                    margin: 2rem 0 1.5rem 0;
                    color: $primary-color;
                    font-weight: 500;
                    font-size: 2.5rem;
                }

                // &-body {
                // }

                span {
                    font-size: 1.3rem;
                    color: rgb(108, 107, 107);
                    margin-top: 1rem;
                }
            }

            &-image-container {
                height: 40rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 0.5rem;
                }
            }
        }
    }
}
// medium small screen
@media (max-width: 415px) {
    .news {
        padding: 7rem 1rem 5rem 1rem;

        &_latest {
            padding: 2rem;
            border-bottom: solid 0.1rem rgb(230, 230, 230);

            h2 {
                color: $secondary-color;
                font-size: 2.6rem;
                margin: 3rem 0;
            }

            h3 {
                margin-top: 4rem;
                font-size: 2rem;
            }

            &-item {
                margin-bottom: 3rem;

                &-title {
                    margin: 2rem 0 1.5rem 0;
                    color: $primary-color;
                    font-weight: 500;
                    font-size: 2.5rem;
                }

                // &-body {
                // }

                span {
                    font-size: 1.3rem;
                    color: rgb(108, 107, 107);
                    margin-top: 1rem;
                }
                button {
                    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.12);
                    border: none;
                    outline: none;
                    padding: 1.4rem 1rem;
                    background-color: $primary-color;
                    border-radius: 10rem;
                    font-size: 1.5rem;
                    width: 12rem;
                    color: $color-white;
                    cursor: pointer;
                    justify-self: flex-end;
                    margin-top: 2.5rem;
                }
            }

            &-image-container {
                height: 40rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 0.5rem;
                }
            }
        }
    }
}

//very small screen
@media (max-width: 295px) {
    .news {
        padding: 7rem 1rem 4rem 1rem;
    }
}

// news posts media queries
@media (max-width: 980px) {
    .news {
        &_latest {
            padding: 2rem;
            border-bottom: solid 0.1rem rgb(230, 230, 230);

            h2 {
                color: $secondary-color;
                font-size: 2.6rem;
                margin: 3rem 0;
            }

            h3 {
                font-size: 2rem;
            }

            &-item {
                margin-bottom: 3rem;

                &-title {
                    margin: 2rem 0 1.5rem 0;
                    color: $primary-color;
                    font-weight: 500;
                    font-size: 2.5rem;
                }

                // &-body {
                // }

                span {
                    font-size: 1.3rem;
                    color: rgb(108, 107, 107);
                    margin-top: 1rem;
                }
            }

            &-image-container {
                height: 40rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 0.5rem;
                }
            }
        }

        &_older {
            h3 {
                margin: 3rem 0;
                font-size: 2rem;
                margin-left: 2rem;
            }

            &-item {
                display: inline-block;
                gap: 2rem;
                align-items: center;

                &:hover {
                    transform: scale(1.01);
                    cursor: pointer;
                    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.12);
                }

                transition: all 0.3s ease;
                padding: 2rem 2rem;
                border-bottom: solid 0.1rem rgb(230, 230, 230);

                &-container {
                    height: 10rem;
                    border-radius: 0.5rem;
                }

                &-title {
                    color: $secondary-color;
                    margin-top: 22rem;
                }
                &-body {
                    font-size: 1.4rem;
                    width: 80%;
                    margin-top: 3rem;
                }

                span {
                    font-size: 1.2rem;
                    color: rgb(108, 107, 107);
                    margin-top: 2rem;
                }

                img {
                    width: 100%;
                    height: 290%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 0.4rem;
                }

                button {
                    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.12);
                    border: none;
                    outline: none;
                    padding: 1rem 1rem;
                    background-color: $primary-color;
                    border-radius: 10rem;
                    font-size: 1.5rem;
                    width: 10rem;
                    color: $color-white;
                    cursor: pointer;
                    justify-self: flex-end;
                    margin-top: 2.5rem;
                }
            }
        }
    }
}
