.contactus {
    padding: 15rem 4rem 10rem 4rem;
    margin-bottom: 7rem;
    // background: linear-gradient(
    //         to bottom,
    //         rgba($primary-color, 0.1),
    //         rgba($primary-color, 0.3)
    //     ),
    //     url(../../assets/images/kids/pic11.jpg);
    // background-size: cover;

    &-icon {
        color: $color-white;
    }

    &-item {
        display: grid;
        grid-template-columns: 15% 75%;
        margin: 1.5rem 0;
        font-size: 1.5rem;

        span {
            display: inline;
            margin-right: 2rem;
            color: lighten($grey-color, 5);
            font-size: 1.5rem;
        }

        &-wrapper {
            margin: 0 auto;
            margin-top: 4rem;
            //display: flex;
            display: grid;
            align-items: center;
        }
    }

    &-info {
        // border: solid 0.1rem black;
        height: 46rem;
        padding: 3rem 3rem;
        background: $color-white;
        border-radius: 1rem;
        background: linear-gradient(
            to left,
            rgba($primary-color, 1),
            rgba(lighten($primary-color, 10), 1)
        );

        //url(../../assets/images/kids/pic11.jpg);
        background-size: cover;
        box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
        h5 {
            //padding: 0;
            color: $color-white;
        }

        p {
            margin-top: 1rem;
            font-size: 1.4rem;
            color: lighten($grey-color, 5);
        }

        span {
            font-size: 18px;
        }
    }

    &-wrapper {
        margin-top: 6rem;
        //border: solid 0.1rem black;
        border-radius: 1rem;
        // box-shadow: 0 0.2rem 0.2rem rgba($secondary-color, 0.7);

        background: $color-white;
    }

    h2 {
        color: $primary-color;
        font-size: 2.5rem;
    }

    h6 {
        color: $secondary-color;
        font-weight: 400;
        font-size: 1.6rem;
    }

    &-map-wrapper {
        width: 100%;
        height: 50rem;
        margin: 5rem 0 0 0;
        position: relative;
    }

    &-button-wrapper {
        margin-top: 3rem;
    }

    &-button {
        display: inline-block;
        position: absolute;
        top: 106%;
        left: 4%;
        transform: translate(-50%, -50%);
        border: none;
        outline: none;
        padding: 0.8rem 0.8rem;
        background-color: $primary-color;
        border-radius: 0.4rem;
        font-size: 1.2rem;
        width: 10rem;
        color: $color-white;
    }
}
// get directions button
.getdirections-btn {
    background-color: rgba($primary-color, 1);
    color: white;
    width: 13rem;
    margin-top: 0.9rem;
    height: 4rem;
}

// Media queries

@media (max-width: 1170px) {
    .contactus {
        // padding: 15rem 5rem 10rem 5rem;
        // background: linear-gradient(
        //         to bottom,
        //         rgba($primary-color, 0.1),
        //         rgba($primary-color, 0.3)
        //     ),
        //     url(../../assets/images/kids/pic11.jpg);
        // background-size: cover;

        &-icon {
            color: $color-white;
        }

        &-item {
            // display: grid;
            // grid-template-columns: 5% 75%;
            margin: 1.5rem 0;
            font-size: 1.5rem;

            span {
                display: inline;
                margin-right: 2rem;
                color: lighten($grey-color, 5);
                font-size: 1.5rem;
            }

            &-wrapper {
                margin: 0 auto;
                margin-top: 4rem;
            }
        }
        &-form {
            width: 90%;
        }

        &-info {
            // border: solid 0.1rem black;
            height: 38rem;
            width: 90%;
            margin: auto;
            padding: 3rem 3rem;
            background: $color-white;
            border-radius: 1rem;
            background: linear-gradient(
                to left,
                rgba($primary-color, 1),
                rgba(lighten($primary-color, 20), 1)
            );

            //url(../../assets/images/kids/pic11.jpg);
            background-size: cover;
            box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
            h5 {
                //padding: 0;
                color: $color-white;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
                color: lighten($grey-color, 5);
            }

            span {
                font-size: 18px;
            }
        }

        &-wrapper {
            display: flex;
            margin-top: 6rem;
            //border: solid 0.1rem black;
            border-radius: 1rem;
            // box-shadow: 0 0.2rem 0.2rem rgba($secondary-color, 0.7);

            background: $color-white;
            flex-direction: column;
        }

        h2 {
            color: $primary-color;
            font-size: 2.5rem;
            margin: auto;
        }

        h6 {
            color: $secondary-color;
            font-weight: 400;
            font-size: 1.6rem;
            margin: auto;
        }

        &-map-wrapper {
            width: 100%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }

        &-button-wrapper {
            margin-top: 3rem;
        }

        &-button {
            display: inline-block;
            position: absolute;
            top: 107%;
            left: 10%;
            transform: translate(-50%, -50%);
            border: none;
            outline: none;
            padding: 0.8rem 0.8rem;
            background-color: $primary-color;
            border-radius: 0.4rem;
            font-size: 1.2rem;
            width: 12rem;
            color: $color-white;
        }
    }
}

@media (max-width: 1072px) {
    .contactus {
        // padding: 15rem 5rem 10rem 5rem;
        // background: linear-gradient(
        //         to bottom,
        //         rgba($primary-color, 0.1),
        //         rgba($primary-color, 0.3)
        //     ),
        //     url(../../assets/images/kids/pic11.jpg);
        // background-size: cover;

        &-icon {
            color: $color-white;
        }

        &-item {
            // display: grid;
            // grid-template-columns: 5% 75%;
            margin: 1.5rem 0;
            font-size: 1.5rem;

            span {
                display: inline;
                margin-right: 2rem;
                color: lighten($grey-color, 5);
                font-size: 1.5rem;
            }

            &-wrapper {
                margin: 0 auto;
                margin-top: 4rem;
            }
        }

        &-info {
            // border: solid 0.1rem black;
            height: 38rem;
            width: 90%;
            margin: auto;
            padding: 3rem 3rem;
            background: $color-white;
            border-radius: 1rem;
            background: linear-gradient(
                to left,
                rgba($primary-color, 1),
                rgba(lighten($primary-color, 20), 1)
            );

            //url(../../assets/images/kids/pic11.jpg);
            background-size: cover;
            box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
            h5 {
                //padding: 0;
                color: $color-white;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
                color: lighten($grey-color, 5);
            }

            span {
                font-size: 18px;
            }
        }

        &-wrapper {
            display: flex;
            margin-top: 6rem;
            //border: solid 0.1rem black;
            border-radius: 1rem;
            // box-shadow: 0 0.2rem 0.2rem rgba($secondary-color, 0.7);

            background: $color-white;
            flex-direction: column;
        }

        h2 {
            color: $primary-color;
            font-size: 2.5rem;
            margin: auto;
        }

        h6 {
            color: $secondary-color;
            font-weight: 400;
            font-size: 1.6rem;
            margin: auto;
        }

        &-map-wrapper {
            width: 100%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }

        &-button-wrapper {
            margin-top: 3rem;
        }

        &-button {
            display: inline-block;
            position: absolute;
            top: 107%;
            left: 8%;
            transform: translate(-50%, -50%);
            border: none;
            outline: none;
            padding: 0.8rem 0.8rem;
            background-color: $primary-color;
            border-radius: 0.4rem;
            font-size: 1.2rem;
            width: 12rem;
            color: $color-white;
        }
    }
}

@media (max-width: 848px) {
    .contactus {
        // padding: 15rem 3rem 10rem 3rem;

        &-icon {
            color: $color-white;
        }

        &-item {
            display: grid;
            grid-template-columns: 10% 75%;
            margin: 1.5rem 0;
            font-size: 1.5rem;

            span {
                display: inline;
                margin-right: 2rem;
                color: lighten($grey-color, 5);
                font-size: 1.5rem;
            }

            &-wrapper {
                margin: 0 auto;
                margin-top: 4rem;
            }
        }

        &-form {
            width: 100%;
        }

        &-info {
            // border: solid 0.1rem black;
            height: 37rem;
            width: 90%;
            // margin: auto;
            padding: 3rem 3rem;
            background: $color-white;
            border-radius: 1rem;
            background: linear-gradient(
                to left,
                rgba($primary-color, 1),
                rgba(lighten($primary-color, 20), 1)
            );

            //url(../../assets/images/kids/pic11.jpg);
            background-size: cover;
            box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
            h5 {
                //padding: 0;
                color: $color-white;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
                color: lighten($grey-color, 5);
            }

            span {
                font-size: 18px;
            }
        }

        &-wrapper {
            display: flex;
            margin-top: 6rem;
            //border: solid 0.1rem black;
            border-radius: 1rem;
            // box-shadow: 0 0.2rem 0.2rem rgba($secondary-color, 0.7);

            background: $color-white;
            flex-direction: column;
        }

        h2 {
            color: $primary-color;
            font-size: 2.5rem;
            margin: auto;
        }

        h6 {
            color: $secondary-color;
            font-weight: 400;
            font-size: 1.6rem;
            margin: auto;
        }

        &-map-wrapper {
            width: 100%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }

        &-button-wrapper {
            margin-top: 3rem;
        }
        &-button {
            display: inline-block;
            position: absolute;
            top: 107%;
            left: 12%;
            transform: translate(-50%, -50%);
            border: none;
            outline: none;
            padding: 0.8rem 1rem;
            background-color: $primary-color;
            border-radius: 0.4rem;
            font-size: 1.3rem;
            width: 12rem;
            color: $color-white;
        }
    }
}

// RESIZING THE MAP and INFORMATION BOX
@media (max-width: 650px) {
    .contactus {
        &-info {
            // border: solid 0.1rem black;
            height: 42rem;
            width: 90%;
            // margin: auto;
            padding: 3rem 3rem;
            background: $color-white;
            border-radius: 1rem;
            background: linear-gradient(
                to left,
                rgba($primary-color, 1),
                rgba(lighten($primary-color, 20), 1)
            );

            //url(../../assets/images/kids/pic11.jpg);
            background-size: cover;
            box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
            h5 {
                //padding: 0;
                color: $color-white;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
                color: lighten($grey-color, 5);
            }

            span {
                font-size: 18px;
            }
        }

        &-map-wrapper {
            width: 100%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }
    }
}

@media (max-width: 430px) {
    .contactus {
        &-info {
            // border: solid 0.1rem black;
            height: 45rem;
            width: 95%;
            // margin: auto;
            padding: 3rem 3rem;
            background: $color-white;
            border-radius: 1rem;
            background: linear-gradient(
                to left,
                rgba($primary-color, 1),
                rgba(lighten($primary-color, 20), 1)
            );

            //url(../../assets/images/kids/pic11.jpg);
            background-size: cover;
            box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
            h5 {
                //padding: 0;
                color: $color-white;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
                color: lighten($grey-color, 5);
            }

            span {
                font-size: 18px;
            }
        }

        &-map-wrapper {
            width: 100%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }
    }
}

@media (max-width: 380px) {
    .contactus {
        &-info {
            // border: solid 0.1rem black;
            height: 42rem;
            width: 95%;
            // margin: auto;
            padding: 3rem 3rem;
            background: $color-white;
            border-radius: 1rem;
            background: linear-gradient(
                to left,
                rgba($primary-color, 1),
                rgba(lighten($primary-color, 20), 1)
            );

            //url(../../assets/images/kids/pic11.jpg);
            background-size: cover;
            box-shadow: 0 0.2rem 0.4rem rgba($black-color, 0.4);
            h5 {
                //padding: 0;
                color: $color-white;
            }

            p {
                margin-top: 1rem;
                font-size: 1.2rem;
                color: lighten($grey-color, 5);
            }

            span {
                font-size: 18px;
            }
        }

        &-map-wrapper {
            width: 130%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }
    }
}

@media (max-width: 315px) {
    .contactus {
        &-map-wrapper {
            width: 160%;
            height: 50rem;
            margin: 5rem 0 0 0;
            position: relative;
        }
    }
}
