.footer {
    &-wrapper {
        @media screen and (max-width: 500px) {
            padding: 0 3rem 5rem 3rem;
        }
    }

    &_social_icon {
        transition: all 0.3s ease;
        cursor: pointer;
        font-size: 1.8rem !important;

        :hover {
            color: $primary-color;
        }
    }
}

#row {
    display: flex;
}

.newsletter {
    margin-top: 3rem;

    @media (max-width: 800px) {
        margin-top: 0rem;

        margin-bottom: 4rem;
    }

    @media (max-width: 600px) {
        padding: 0 !important;
    }
}

// .hm-footer-copyright .footer-social .footer_social_icon {
//     color: $primary-color;
// }

//-- Footer media query --------------------------------------------------------------------------//

@media (max-width: 650px) {
    #row {
        display: flex;
        flex-direction: column;

        //    text-align: center;
    }

    .hm-foot-menu ul li a {
        padding: 2px;
    }

    .hm-foot-title h4 {
        margin-bottom: 4rem;
    }
}

@media (max-width: 765px) {
    // #row {
    //    width: 68rem;
    // }

    .hm-foot-menu ul li a {
        padding: 2px;
    }

    .hm-foot-title h4 {
        margin-bottom: 4rem;
    }
}

@media (min-width: 800px) {
    .hm-foot-menu > ul > li > a {
        padding: 0 !important;
    }
}
