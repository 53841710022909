.header-carousel-wrapper {
    position: relative;
}

.header-text-container {
    z-index: 999;
    position: absolute;
    display: grid;
    justify-content: center;
    align-content: center;

    left: 30%;
    top: 65%;
    transform: translate(-50%, -50%);
    color: $color-white;
    height: 25rem;
    width: 80rem;
    background: linear-gradient(
        to left,
        transparent 0%,
        rgba(0, 0, 0, 0.35) 15%,
        rgba(0, 0, 0, 0.35) 85%,
        transparent 100%
    );

    h5 {
        font-size: 2.8rem;
        font-weight: 500;
        // color: $grey-color;
        color: white;
        letter-spacing: 0.5rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.4rem;
        letter-spacing: 0.5rem;
        font-weight: 400;
        color: $secondary-color;
        margin-bottom: 2.5rem;
    }

    button {
        border: none;
        outline: none;
        padding: 0.8rem 1rem;
        background-color: $primary-color;
        border-radius: 10rem;
        font-size: 1.5rem;
        width: 40%;
    }
}

.welcome-hero-img img {
    position: relative;
    z-index: -1 !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.welcome-hero-img {
    padding: 0 !important;
    height: 100vh !important;
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.5) 15%,
        transparent 50%,
        rgba(0, 0, 0, 0.3) 85%,
        rgba(0, 0, 0, 0.5) 100%
    ) !important;

    @media (max-width: 800px) {
        height: 62vh !important;
        margin-top: -2rem; // move the image up
    }
}

#header-carousel {
    position: relative !important;
}

#header-carousel {
    z-index: 20 !important;
    position: absolute;
    top: 0;
    display: block;
}

.carousel-indicators {
    bottom: -2%;
}

.single-welcome-hero {
    padding-bottom: 4rem;
}

//-- Header media query ------------------------------------------------------------//

@media (max-width: 920px) {
    .header-text-container {
        top: 73%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
        height: 25rem;
        width: 45rem;

        h5 {
            font-size: 2.8rem;
            font-weight: 500;
            // color: $grey-color;
            color: white;
            letter-spacing: 0.5rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.4rem;
            letter-spacing: 0.5rem;
            font-weight: 400;
            color: $secondary-color;
            margin-bottom: 2.5rem;
        }

        button {
            border: none;
            outline: none;
            padding: 0.8rem 1rem;
            background-color: $primary-color;
            border-radius: 10rem;
            font-size: 1.5rem;
            width: 40%;
        }
    }
}

@media (max-width: 510px) {
    .header-text-container {
        top: 81%;
        left: 50%;
        height: 25rem;
        width: 29rem;

        h5 {
            font-size: 2.4rem;
            font-weight: 500;
            // color: $grey-color;
            color: white;
            letter-spacing: 0.5rem;
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 365px) {
    .header-text-container {
        left: 50%;
        top: 60%;
        height: 25rem;
        width: 20rem;
    }
}

@media (max-width: 767px) {
    .header-text-container {
        top: 70%;
        left: 50%;
        height: 25rem;
        width: 30rem;
    }
}
@media (max-width: 290px) {
    .header-text-container {
        left: 50%;
        height: 25rem;
        width: 24rem;
    }
}
