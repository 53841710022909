.logo-container {
    display: inline-block;
    width: 17rem;
    margin-right: 3rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (max-width: 900px) {
        //display: none !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 13rem;
    }

    @media (max-width: 600px) {
        //display: none !important;
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        width: 11rem;
    }
}

.nav-items {
}

.navbar-header {
    position: relative;

    display: flex !important;

    & > button {
        width: 8rem;

        @media (max-width: 600px) {
            width: 5rem;
        }
    }
}

.navbar-brand {
    font-size: 1.6rem !important;
    font-weight: 300 !important;
    word-spacing: 1rem;
    margin-top: -1.5rem;

    span {
        letter-spacing: 0.5rem;
        font-size: 1.8rem !important;
        font-weight: 600 !important;
        padding-right: 1rem !important;
    }

    @media (min-width: 95rem) {
        //display: none !important;
    }
}

@media (max-width: 90rem) {
    .attr-nav {
        display: none;
    }
}
