.contact-form {
    width: 100%;
    position: relative;
    padding: 0rem 5rem 5rem 5rem;
    margin: 0rem;
    //z-index: 2000;
    background: $color-white;
    border-radius: 0.5rem;

    &-container {
        width: 100%;
    }

    &-button {
        &:hover {
            background-color: $secondary-color;
            color: $color-white;
        }

        transition: all ease-in 0.3s;
        display: inline-block;
        border: none;
        outline: none;
        padding: 1.2rem 2.5rem;
        background-color: $secondary-color;
        border-radius: 0.4rem;
        font-size: 1.5rem;
        width: 100%;
        color: $color-white;
        border: solid 0.1rem $secondary-color;
        margin-top: 2.5rem;
        float: right;
    }

    &-item {
        padding: 5rem;
    }

    &-material-textfield {
        position: relative;
    }

    &-row {
        margin: 2rem;
        position: relative;
    }

    label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    .icon {
        display: inline-block;
        margin-right: 1.6rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    &-textarea span {
        display: inline-block;
        position: absolute;
        margin-right: 1.6rem;
        right: 0;
        top: 85%;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    &-textarea label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 0;
        top: 10%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    input {
        font-size: 1.6rem;
        outline: none;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        color: gray;
        transition: 0.1s ease-out;
        width: 100%;
        height: 5.5rem;
    }
    input:focus {
        border-color: $primary-color;
    }
    input:focus + label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
    input:not(:placeholder-shown) + label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    textarea {
        resize: none;
        font-size: 1.6rem;
        outline: none;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        color: gray;
        transition: 0.1s ease-out;
        width: 100%;
        height: 15rem;
    }
    textarea:focus {
        border-color: $primary-color;
    }
    textarea:focus + label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
    textarea:not(:placeholder-shown) + label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    .contact-form-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        @keyframes spin {
            to {
                -webkit-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin {
            to {
                -webkit-transform: rotate(360deg);
            }
        }
    }
}

select,
input,
textarea,
button {
    box-shadow: none;
    outline: 0 !important;
    cursor: pointer;
    width: 100%;
    height: 5.5rem;
    font-size: 1.6rem;
}

// contact form for contact us page

.contact-form-page {
    width: 100%;
    position: relative;
    padding: 0rem 5rem 5rem 5rem;
    margin: 0rem;
    //z-index: 2000;
    background: $color-white;
    border-radius: 0.5rem;

    &-container {
        width: 100%;
    }

    &-button {
        &:hover {
            background-color: $secondary-color;
            color: $color-white;
        }

        transition: all ease-in 0.3s;
        display: inline-block;
        border: none;
        outline: none;
        padding: 1.2rem 2.5rem;
        background-color: $secondary-color;
        border-radius: 0.4rem;
        font-size: 1.5rem;
        width: 100%;
        color: $color-white;
        border: solid 0.1rem $secondary-color;
        margin-top: 2.5rem;
    }

    &-item {
        padding: 5rem;
    }

    &-material-textfield {
        position: relative;
    }

    &-row {
        margin: 2rem;
        position: relative;
    }

    label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    .icon {
        display: inline-block;
        margin-right: 1.6rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    &-textarea span {
        display: inline-block;
        position: absolute;
        margin-right: 1.6rem;
        right: 0;
        top: 85%;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    &-textarea label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 0;
        top: 10%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    input {
        font-size: 1.6rem;
        outline: none;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        color: gray;
        transition: 0.1s ease-out;
        width: 100%;
        height: 5.5rem;
    }
    input:focus {
        border-color: $primary-color;
    }
    input:focus + label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
    input:not(:placeholder-shown) + label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    textarea {
        resize: none;
        font-size: 1.6rem;
        outline: none;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        color: gray;
        transition: 0.1s ease-out;
        width: 100%;
        height: 15rem;
    }
    textarea:focus {
        border-color: $primary-color;
    }
    textarea:focus + label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
    textarea:not(:placeholder-shown) + label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
}

select,
input,
textarea,
button {
    box-shadow: none;
    outline: 0 !important;
    cursor: pointer;
    width: 100%;
    height: 5.5rem;
    font-size: 1.6rem;
}

select {
    padding: 0rem 1rem 0rem 1rem;
}

//  media queries FOR CONTACT FORM MODAL :)

@media (max-width: 1170px) {
    .contact-form {
        min-width: 100%;
        position: relative;
        padding: 2rem 0rem 2rem 12rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-container {
            width: 100%;
        }

        &-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .col-md-6 {
            padding-top: 1rem !important;
            padding-left: 0rem !important;
        }
        .col-md-12 {
            padding-left: 0rem !important;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
}

@media (max-width: 1168px) {
    .contact-form {
        width: 100%;
        position: relative;
        padding: 1rem 0rem 2rem 2rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-container {
            width: 100%;
        }

        &-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .col-md-6 {
            padding-top: 1rem !important;
            padding-left: 0rem !important;
        }
        .col-md-12 {
            padding-left: 0rem !important;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
}

@media (max-width: 1155px) {
    .contact-form {
        width: 100%;
        position: relative;
        padding: 1rem 0rem 2rem 2rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-container {
            width: 100%;
        }

        &-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .col-md-6 {
            padding-top: 1rem !important;
            padding-left: 0rem !important;
        }
        .col-md-12 {
            padding-left: 0rem !important;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
}

@media (max-width: 600px) {
    .contact-form {
        width: 100%;
        position: relative;
        padding: 1rem 0rem 2rem 2rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;
    }
}

// MEDIA QUERIES FOR CONTACT US PAGE FORM :)

@media (max-width: 1170px) {
    .contactus-form {
        margin: auto;
        padding: 0rem;
    }
    .contact-form-page {
        // min-width: 120%;
        position: relative;
        padding: 0rem;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-container {
            width: 100%;
        }

        &-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .col-md-6 {
            padding-top: 1rem !important;
            padding-left: 0rem !important;
        }
        .col-md-12 {
            padding-left: 0rem !important;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
    .contactform-pageContainer {
        //    margin-left: 40rem;
        //    width: 72rem;
    }
}

@media (max-width: 1075px) {
    .contactus-form {
        margin: auto;
        padding: 0rem;
    }
    .contact-form-page {
        // width: 145%;
        position: relative;
        padding: 0rem;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-container {
            width: 100%;
        }

        &-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        span {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .col-md-6 {
            padding-top: 1rem !important;
            padding-left: 0rem !important;
        }
        .col-md-12 {
            padding-left: 0rem !important;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
    .contactform-pageContainer {
        width: 70rem;
    }
}

@media (max-width: 999px) {
    .contact-form-page {
        // width: 90%;
        // position: relative;
        padding: 2rem 0rem 2rem 0rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-contactform-pageContainer {
            width: 20%;
        }

        &-contact-form-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
    .contactform-pageContainer {
        width: 70rem;
    }
}

@media (max-width: 700px) {
    .contactus-form {
        width: 90%;
    }
    .contact-form-page {
        //width: 90%;
        // position: relative;
        padding: 2rem 0rem 2rem 0rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-contactform-pageContainer {
            width: 20%;
        }

        &-contact-form-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
    .contactform-pageContainer {
        width: 55rem;
    }
}

@media (max-width: 550px) {
    .contactus-form {
        width: 100%;
    }
    .contact-form-page {
        // width: 90%;
        // position: relative;
        padding: 2rem 0rem 2rem 0rem;
        margin: 0 auto;
        //z-index: 2000;
        background: $color-white;
        border-radius: 0.5rem;

        &-contactform-pageContainer {
            width: 20%;
        }

        &-contact-form-button {
            &:hover {
                background-color: $secondary-color;
                color: $color-white;
            }

            transition: all ease-in 0.3s;
            display: inline-block;
            border: none;
            outline: none;
            padding: 1.2rem 2.5rem;
            background-color: $secondary-color;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            width: 100%;
            color: $color-white;
            border: solid 0.1rem $secondary-color;
            margin-top: 2.5rem;
        }

        &-item {
            padding: 5rem;
        }

        &-material-textfield {
            position: relative;
        }

        &-row {
            margin: 2rem;
            position: relative;
        }

        label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        .icon {
            display: inline-block;
            margin-right: 1.6rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea span {
            display: inline-block;
            position: absolute;
            margin-right: 1.6rem;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            font-size: 1.7rem;
        }

        &-textarea label {
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            left: 0;
            top: 10%;
            transform: translateY(-50%);
            background-color: white;
            color: gray;
            margin: 0 0.5rem;
            transition: 0.1s ease-out;
            transform-origin: left top;
            pointer-events: none;
        }

        input {
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            height: 5.5rem;
        }
        input:focus {
            border-color: $primary-color;
        }
        input:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }

        textarea {
            resize: none;
            font-size: 1.6rem;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 1rem 2rem;
            color: gray;
            transition: 0.1s ease-out;
            width: 100%;
            //height: 4.8rem;
        }
        textarea:focus {
            border-color: $primary-color;
        }
        textarea:focus + label {
            color: $primary-color;
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
        textarea:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-50%) scale(0.9);
        }
    }
    .contactform-pageContainer {
        width: 100%;
    }
}
