@use '../abstracts' as a;

.donate_container {
    box-sizing: border-box;

    box-shadow: inset 0.2rem 0.4rem 0.8rem rgba($color: #a6a6a6, $alpha: 0.3);
    border-radius: 5px;
    // margin: auto;
    padding: 10rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;

    @media (max-width: 800px) {
        padding: 10rem 2rem 5rem 2rem;
        width: 100%;
        flex-direction: column;
    }
}

.donate_heading {
    font-size: 2.5rem;
    color: $primary-color;
    padding-bottom: 2rem;
}

.donateImg {
    padding: 0rem 4rem;

    @media (max-width: 600px) {
        padding: 0rem 2rem;
    }
}

.imagedonate {}

// form
.donate_form {
    margin-top: 1rem;
    padding: 0rem 4rem;
    display: none;

    @media (max-width: 600px) {
        padding: 0rem 2rem;
    }

    input {
        font-size: 14px;
        color: rgba(#000, 0.8);
    }
}

.donation_Info_container {
    padding: 0rem 3rem 0rem 3rem;
    margin-top: 1rem;

    .tax-reduction {
        color: $secondary-color;
    }

    .donate-question {
        font-size: 1.4rem;
    }

    .tax-email {
        color: $primary-color;
    }

    h1 {
        padding: 2rem 0rem 2rem 0rem;
        color: $primary-color;
    }

    p {
        padding-bottom: 2rem;
    }
}

.name-container {
    display: flex;
    gap: 1rem;

    @media (max-width: 900px) {
        flex-direction: column;
        gap: 10px;
    }
}

.makeDonationBtn {
    width: 35%;
    padding: 1.1rem;
    font-size: 1.3rem;
    color: whitesmoke;
    background-color: $primary-color;
    border-radius: 50px;
}

.cardHolder_name {
    width: 100%;
    position: relative;
    padding-top: 1rem;
    margin: 0 auto;
    //z-index: 2000;
    background: $color-white;

    label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    span {
        display: inline-block;
        margin-right: 1.6rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    &-textarea label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 5px;
        top: 10%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    input {
        font-size: 14px;
        outline: none;
        border: 1px solid gray;
        border-radius: 0.4rem;
        padding: 1rem 2rem;
        color: rgba(#000, 0.8);
        transition: 0.1s ease-out;
        width: 100%;
        //height: 4.5rem;
    }

    input:focus {
        border-color: $primary-color;
    }

    input:focus+label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    input:not(:placeholder-shown)+label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    textarea {
        resize: none;
        font-size: 1.6rem;
        outline: none;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        color: gray;
        transition: 0.1s ease-out;
        width: 100%;
        //height: 4.8rem;
    }

    textarea:focus {
        border-color: $primary-color;
    }

    textarea:focus+label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    // textarea:not(:placeholder-shown) + label {
    //     top: 0;
    //     transform: translateY(-50%) scale(0.9);
    // }
}

//
.amount-container {
    position: relative;
    display: grid;
    grid-template-columns: 50% 25% 25%;

    @media (max-width: 800px) {
        width: 100%;
        grid-template-columns: 40% 25% 35%;
    }

    @media (max-width: 600px) {
        display: flex;
    }

    .currency-dropdown {
        cursor: pointer;
        border: none;
        outline: none;
        color: $color-white;
        background: linear-gradient(to left,
                $secondary-color,
                $secondary-color );
        border-radius: 0 5px 5px 0;
        padding-left: 1.5rem;
        display: flex;
        align-items: center;

        .code {
            margin-left: 1rem;
            font-size: 1.4rem;
        }

        @media (max-width: 600px) {
            width: 50%;
        }
    }
}

.amount {
    position: relative;
    width: 100%;
    position: relative;
    padding-top: 1rem;
    margin: 0 auto;
    //z-index: 2000;
    background: $color-white;

    @media (max-width: 600px) {
        margin-bottom: 20px;
    }

    label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    span {
        display: inline-block;
        margin-right: 1.6rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    &-textarea label {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        left: 5px;
        top: 10%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }

    .amount-in-rands {
        padding-top: 1.5rem;
        display: flex;
        align-content: baseline;
        justify-content: center;
        font-size: 1.4rem;
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;

        @media (max-width: 600px) {
            justify-content: left;
            padding: 0;
            padding: 10px 0;
            padding-left: 5px;
            position: absolute;
            bottom: -32px;
            left: 0;
            width: 100%;
            border: 0;
        }
    }

    .flag {
        height: 20px;
        width: 30px;
        position: relative;

        span {
            margin: 0;
        }
    }

    input {
        font-size: 14px;
        outline: none;
        border: none;
        border-left: 1px solid gray;
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;

        border-radius: 0.4rem 0 0 0.4rem;
        padding: 1rem 2rem;
        color: rgba(#000, 0.8);
        transition: 0.1s ease-out;
        width: 100%;
        //height: 4.5rem;
    }

    input:focus {
        border-color: $primary-color;
    }

    input:focus+label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    input:not(:placeholder-shown)+label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    textarea {
        resize: none;
        font-size: 1.6rem;
        outline: none;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        color: gray;
        transition: 0.1s ease-out;
        width: 100%;
        //height: 4.8rem;
    }

    textarea:focus {
        border-color: $primary-color;
    }

    textarea:focus+label {
        color: $primary-color;
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    textarea:not(:placeholder-shown)+label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
}

.m-push-down {
    margin-top: 35px !important;
}

.donateBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    border: 1px solid black;
    width: 100%;
    margin-top: 2rem;
    padding: 1.3rem;
    font-size: 1.5rem;
    color: whitesmoke;
    background-color: $primary-color;
    border-radius: 5px;
}

._exp {
    margin-right: 1rem;
}

._cvv {
    margin-left: 1rem;
}

#reason4Name {
    font-size: 1.5rem;
    margin-bottom: 3rem;
}

.custom-select-wrapper {
    position: relative;

    .selected-currency {
        font-size: 14px;
        color: #000;
    }

    .currencies {
        display: inline-block;
        position: absolute;
        top: 0.5rem;
        height: 180px;
        width: 100%;
        overflow-y: auto;
        background: white;
        box-shadow: 0 4px 8px rgba(#000, 0.2);
        border-radius: 4px;
        padding: 5px 10px;

        .search-box {
            input {
                border-bottom: solid 1px rgb(239, 238, 238);
                font-size: 14px;
                outline: none;
                border: none;
                border-bottom: 0.1rem rgb(213, 213, 213) solid;
                border-radius: 0.4rem 0 0 0.4rem;
                padding: 0.5rem 1rem;
                color: rgba(#000, 0.8);
                transition: 0.1s ease-out;
                width: 100%;
            }
        }

        .currency {
            font-size: 1.2rem;
            color: rgba(0, 0, 0, 0.8);
            padding: 10px 0px;
            border-bottom: solid 1px rgb(239, 238, 238);
            cursor: pointer;
            transition: all ease 0.2s;
            font-size: 14px;
            display: flex;
            gap: 10px;
            position: relative;

            &:hover {
                background: rgb(235, 235, 235);
            }

            .flag {
                height: 20px;
                width: 30px;
                position: relative;

                span {
                    margin: 0;
                }
            }
        }
    }

    .no-rates {
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 1.4rem;
        color: $secondary-color;
    }
}

.spinner {
    &-active {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        border: 0.3rem solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: white;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}