#voluteer-button {
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.12);
    border: none;
    outline: none;
    padding: 1.4rem 1rem;
    background-color: $primary-color;
    border-radius: 10rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    width: 30%;
    color: $color-white;
    cursor: pointer;
    // z-index: 9999;
    // position: relative;
}

.volunteer-cta {
    box-shadow: 0.2rem 0.4rem 0.8rem rgba($color: #696969, $alpha: 0.2);
    margin: 10rem 0 10rem 0;
}

/*-------------------------------------
        6. Sofa-coolection
--------------------------------------*/
#sofa-collection {
    position: relative;
    // z-index: -1;
}

.sofa-collection {
    position: relative;
    display: flex;
    align-items: center;
    height: 600px;
    background-position: center;
    background-size: cover;
    padding: 4rem;
}

// .sofa-collection.collectionbg1 {

//     background: url(../../assets/images/kids/pic14.jpg)no-repeat;
// }

.sofa-collection.collectionbg2 {
    min-height: 500px;
    height: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: url(../../assets/images/kids/pic12.jpg) no-repeat;
}

.sofa-collection:before,
.sofa-collection:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(51, 59, 62, 0.44);
}

.sofa-collection:after {
    background: linear-gradient(
        to right,
        transparent,
        rgba($secondary-color, 0.4)
    );
    left: auto;
    right: 0;
    width: 60%;
}

.sofa-collection-txt {
    position: relative;
    max-width: 596px;
    // z-index: 1;
}

.sofa-collection-txt h2 {
    font-size: 2.5rem;
    color: darken($secondary-color, 2%);
    font-weight: 500;
}

.sofa-collection-txt h4 {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
}

.sofa-collection-txt h4 span {
    color: var(--primary-color);
}

.sofa-collection-txt p {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin: 25px 0 10px;
}

.sofa-collection-price {
    margin-bottom: 3px;
}

//-- volunteer media query -----------------------------------------------------------------/

@media (max-width: 650px) {
    .sofa-collection {
        padding: 4rem;
    }

    #voluteer-button {
        margin-top: 2rem;
        width: 15rem;
    }
}